.footer-container {
    background-color: black;
    color: white;
    padding: 40px 5%; /* Default padding for small to medium devices */
    bottom: 0;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }

  .footer-copyright{
    padding: 0px 5% 15px 5%; /* Default padding for small to medium devices */
    background-color: black;
    color: white;
  }
  

  
  /* Media query for medium devices (min-width: 768px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .footer-container {
      padding: 50px 5%; /* Adjusted padding for medium devices */
    }
  }
  
  /* Media query for large devices (min-width: 992px) */
  @media (min-width: 992px) {
    .footer-container {
      padding: 50px 5%; /* Adjusted padding for large devices */
    }
  }
  
  