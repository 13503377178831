/* styles.css */
[data-doorman] {
    visibility: hidden;
  }
   
/* Global CSS file */
body {
  font-family: Futura, sans-serif; /* Apply Futura font globally */
}

/* Global CSS file */
.futura-font {
  font-family: Futura, sans-serif; /* Apply Futura font to elements with this class */
}

/* styles.css */
.card {
  backdrop-filter: blur(5px) saturate(75%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: white;
  box-shadow: 3px -1px 32px 0 rgb(165 165 165 / 72%);
}

h1{
  font-size: 22px;
  letter-spacing: 0.2em;
  font-weight: 400;
}

p{
  font-family: Futura, sans-serif;
  font-size: 14px;
  color:#1c1b1b;
}

.landing-section{
  position: relative;
  height: 90vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
}

.leftButton{
  width: auto;
  height: 40px;
  margin-right: 20px!important;
  border-radius: 0!important;
  background-color: white!important;
  color: #363636;
}

.righButton{
  width: auto; 
  height: 40px;
  border-radius: 0!important;
  background-color: white!important;
  color: #363636;
}

.servicePageButton{
  width: fit-content;
  margin-right: 20px!important;
  margin-bottom: 20px!important;
  border-radius: 0!important;
  background-color: black;
}

.landingButtonsTypography{
  text-transform: uppercase!important;
  padding: 14px 18px!important;
  font-size: 12px!important;
  text-align: center!important;
  letter-spacing: 0.2em!important;
  font-family: Futura, sans-serif!important;
  font-weight: 400!important;
  font-style: normal!important;
}

.content-section{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  background-size: cover;
  background-position :center;
}

.content-card{
  max-width: 520px;
  padding: 48px 15px;
  background-color : white;
  text-align: center;
}

.section-fifth{
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10% 0px 10%;
  border-bottom: 0.5px solid #6a685f6b;  
}

.typography-center{
  text-align: center;
}

.policies_links{
  font-family: Futura, sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  color: #454545;
  background: #fff;
  line-height: 1.65;
}

.content_panel{
  padding: 0px 240px;
}

.accordion_widget_title{
  font-size: 14px;
  font-family: Futura, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #454545;
  line-height: 1.65;
  margin-bottom: 12px;
}

.accordion_title{
      margin-bottom: 34px;
      font-size: 20px;
      font-family: Futura, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #454545;
    -webkit-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
    letter-spacing: .2em;
    text-transform: uppercase;
}
.accordion_sub_title{
  margin: 12px 0;
  font-family: Futura, sans-serif;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  color: #454545;
  -webkit-transition: color .2s ease-in-out;
  transition: color .2s ease-in-out;
  letter-spacing: 0em;
}

.accordion_sub-description{
  font-family: Futura, sans-serif;
    font-weight: 100;
    font-style: normal;
    color: #454545;
    background: #fff;
    line-height: 1.65;
    font-size: 14px;
    letter-spacing: 0.001em;
    margin-Left:22px;
}

.services_content{
  margin: 35px auto;
  max-width: 1000px;
}

.appointment-component {
  /* Default height */
  height: 100vh;
}

.accordion_font_family{
  font-family: Futura, sans-serif!important;
}

.staff_heading{
  font-size: 13px!important;
  letter-spacing: 0.045rem!important;
}

.accordion_head{
  font-family: Futura, sans-serif!important;
  font-size: 13.5px!important;
  font-weight: 200!important;
  letter-spacing: 0.045rem!important;
}
.accordion_sub_heading{
  font-family: Futura, sans-serif!important;
  font-size: 13px!important;
  letter-spacing: 0.045rem!important;

}
.accordion_price{
  font-size: 13px!important;
  letter-spacing: 0.045rem!important;
}
.accordion_tool_tip{
  font-size: 11px!important;
}
.services_table th{
  border: 1px solid grey;
  padding: 8px;
  background-color : #000000;
  color: white;
  font-family: Futura, sans-serif;
  font-size: 10px;
  letter-spacing: 0.078rem;
  font-weight: 300;
}

.services_table_data td{
  /* border: 1px solid grey; */
  padding: 8px;
  font-family: Futura, sans-serif;
  font-size: 10px;
  letter-spacing: 0.078rem;
  font-weight: 400;
}

.services_table_data th p, .service_heading {
  /* font-weight: 600!important; */
  letter-spacing: 0.076rem!important;
  color: #303030db;
}

/* styles.css */

/* Add this media query for smaller screens */
@media (max-width: 768px) {
  .content_panel{
    padding: 0px 10px;
  }
  .link {
    font-size: 10px!important; /* font size for xs */
    line-height: 1.5; /* line height for xs */
  }
  section {
    height: auto; /* Change height to auto for smaller screens */
  }

  .heading-mobile{
    padding: 0px 50px!important;
  }

  .button-container {
    flex-direction: column; /* Change flex-direction to column for smaller screens */
    margin-top: 20px; /* Add margin-top for spacing */
    align-items: center;
  }

  .leftButton, .righButton{
    margin-right: 0px!important;
    width: 245px!important;
  }

  .button-container button {
    margin-bottom: 10px; /* Add margin-bottom for spacing between buttons */
  }

  .card{
    margin: auto 30px;
  }
  .card p{
    font-size: 12px;
  }
  .section-fourth{
    padding: 35px 10% 86px!important;
  }
  .fourthImage{
    max-width: 100%!important;
    height: 300px!important;
  }
  .accordion_widget{
    display: none;
  }
  .accordion_body{
    margin-left: 0px!important;
    padding:0px 5px!important;
  }
  .accordion_body_image{
    max-width: 100%!important;
  }
  .accordion_head{
    font-family: Futura, sans-serif!important;
    font-size: 12px!important;
    letter-spacing: 0.045rem!important;
  }
  .accordion_sub_heading , .staff_heading{
    font-size: 11px!important;
    letter-spacing: 0.045rem!important;
  
  }
  .accordion_wrapper{
    padding-left: 5px!important;
    padding-right: 5px!important;
  }
  .accordion_icon_wrapper{
    font-size: 10px!important;
    min-width: 24px!important;
  }

  .accordion_staff_wrapper div{
    min-width: 24px!important;
  }

  .accordion_staff_wrapper div .MuiCheckbox-root .MuiSvgIcon-root{
    font-size: 1rem!important;
  } 

  .accordion_icon_wrapper .MuiCheckbox-root .MuiSvgIcon-root {
    font-size: 1rem!important;
  }
  .accordion_price{
    font-size: 10px!important;
    letter-spacing: 0.045rem!important;
  }
  .accordion_tool_tip{
    font-size: 9px!important;
    height: 20px!important;
    letter-spacing: 0.045rem!important;
  }
  .accordion_chip .MuiSvgIcon-root{
    font-size: 9px!important;
  }
  .dialog-container{
    width:80%!important;
  }
  .dialog-inner-container{
    padding: 10px 5px!important;
  }
}

@media screen and (min-width: 641px){
  .section-back {
      min-height: 480px!important;
  }
  }

@media screen and (min-width: 1240px){
  .section-back {
    min-height: 600px!important;
}
}


.dialog-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 10px;
  width: 80%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  box-shadow: rgb(0 0 0 / 6%) -2px -5px 11px 0px, rgba(0, 0, 0, 0.23) 1px 0px 6px;;
}

.navigation-link {
  text-decoration: none;
  font-family: Futura, sans-serif;
  color: #8d8f8f;
  transition: all .2s ease-in-out;
  line-height: 1.5;
  font-size: 13px;
  font-weight: 200;
  cursor: pointer;
}


.link {
  font-size: 10px; /* default font size for md */
  letter-spacing: 0.2em;
  line-height: 1.5; /* default line height for md */
  color: white;
  text-decoration: none; /* remove underline */
  text-align: center;
}

.link2 {
  font-size: 10px; /* default font size for md */
  letter-spacing: 0.05em;
  line-height: 1.5; /* default line height for md */
    color: black;
  text-align: center;
}



.accordion {
  display: flex;
}

.accordion-titles {
  width: 200px;
  background-color: #f0f0f0;
}

.accordion-title {
  padding: 10px;
  cursor: pointer;
}

.accordion-title.active {
  background-color: #e0e0e0;
}

.accordion-contents {
  flex: 1;
  padding: 10px;
}

.content-item {
  margin-bottom: 20px;
}
.ql-align-center {
  text-align: center;
}
.ql-editor h1 {
  margin-bottom: 30px;
}

.ql-editor p,.ql-editor ul {
  margin-bottom: 30px;
  font-weight: 100;
  font-size: 13px;
  line-height: 1.5;
  font-family: 'Futura', sans-serif;
  letter-spacing: 0.02em;
}

.menu-hr {
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  margin: 8px 0px;
  background-color: rgba(255, 255, 255, 0.78);
}
.menu-achnor p{margin-bottom: 0;}
.menu-achnor{
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  color: inherit;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 8px 16px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.btn {
  padding: 12px 30px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.servicePageButton:hover{
  background-color: #1c1b1b;
  color:wheat
}